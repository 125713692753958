/*
Documentation

This is the top half of the navbar on the dashboard layout

*/

import keys from 'keys'

import React from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, NavItem, UncontrolledDropdown } from "reactstrap";
import { toggleRecentActivity } from 'store/functions/system/system'

import _navbar from '_functions/navbar'

import AvatarImage from 'components/functional/images/AvatarImage';
import ContactAndCaseSearchInput from 'views/dashboard/pages/_components/ContactAndCaseSearchInput';

import FindCase from '../modals/FindCase';

import UserDropdown from './components/UserDropdown'
import * as privileges from '_functions/users/privileges';
import _zendesk from '_functions/zendesk';
import {canViewTickets} from '_functions/users/privileges'

class NavbarTop extends React.Component {

	state = {
        totalTickets: 'none',
		showModalFindCase: false,
		showModalFindContact: false,

        my_tasks: '-',
        my_appointments: '-',
        my_messaging_center: '-',
        my_voicemail: '-',
	}

    interval;
    ticketInterval;

    getOffice = () => {

        const office_id = this.props.viewing_user.user_set_location;
        if(office_id) {
            const foundOffice = this.props.offices.find(o => o._id === office_id);
            return foundOffice ? foundOffice.name : 'Unknown';
        }

        return 'Home'
        
    }

    renderCountNumber = (number) => number > 99 ? '99+' : number;
    renderCountColor = (number) => number === 0 ? 'text-success' : number < 99 ? 'text-info-original' : 'text-danger';


    fetchTickets = async () => {
        const data = await _zendesk.tickets();
        if(data.data && data.data.unassigned) {
            let total = data.data.unassigned.count
            total += data.data.kevin.count
            total += data.data.diana.count
            total += data.data.shirley.count
            total += data.data.michael.count
            this.setState({totalTickets: total})
        }
    }

    

    onQuerySelected = (object, isLink) => {

        if(object && object._id) {

            if(object.workflow) {

                const shouldRedirect = `/dashboard/cases/view/${object._id}`;

                if(isLink) {
                    window.open(shouldRedirect, "_blank");
                } else {
                    this.setState({shouldRedirect}, () => {
                        this.setState({shouldRedirect: false})
                    })
                }

            } else {

                const shouldRedirect = `/dashboard/contacts/view/${object._id}`;

                if(isLink) {
                    window.open(shouldRedirect, "_blank");
                } else {
                    this.setState({shouldRedirect}, () => {
                        this.setState({shouldRedirect: false})
                    })
                }
                
            }

        }


    }

    getCount = async () => {
        const counts = await _navbar.getCounts(this.props.viewing_user._id)
        if(counts.data) this.setState({...counts.data})
    }

    componentWillUnmount = () => {
        clearInterval(this.interval)
        if(this.ticketInterval) clearInterval(this.ticketInterval)
    }

    componentDidMount = () => {
        this.interval = setInterval(this.getCount, 30000)
        this.ticketInterval = setInterval(this.fetchTickets, 60000)
        this.getCount()

        if(canViewTickets()) {
            this.fetchTickets()

        }
    }

	render() {

        const { my_tasks, my_voicemail, my_messaging_center, my_appointments, shouldRedirect, totalTickets } = this.state
		const viewing_user = this.props.viewing_user

    	return (

			<>



			<Navbar style={{background: '#053A53'}} className="dashboard-nav navbar-top navbar-expand border-bottom navbar d-none d-md-block"  >
				<Container fluid>

					<Nav className="align-items-center d-none d-md-block" navbar>
						<NavItem className="mr-0">
							<Link to="/dashboard">
								<img style={{position: 'relative', top: -1}} alt="..." className="navbar-brand-img mr-3" src={keys.LOGO} />
							</Link>

						</NavItem>

					</Nav>

				</Container>
			</Navbar>

			</>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
    	nylas_invalid: state.system.nylas_invalid,
    	viewing_user: state.auth.viewing_user,
    	offices: state.offices.offices,
  	};
};

export default connect(mapStateToProps, '')(NavbarTop);
