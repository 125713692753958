import { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux'
import { Container, FormGroup, Input, FormFeedback, Badge } from 'reactstrap';
import Logo from 'assets/img/brand/BizHeadLogoCut.png'

import _nylas from '_functions/nylas'
import _contacts from '_functions/contacts'

import Circle from 'components/markup/loading/Circle'
import moment from 'moment';
import { toggleAlertBS } from 'store/functions/system/system';
import { getUrlParameter } from 'utils/urls';
import renderName from 'utils/renderName'

import { formatPhone } from 'utils/text';

import keys from 'keys'

const Book = ({on_call, setNeedsOutcome}) => {

    const [now] = useState(Math.floor(new Date() / 1000));
    const [contact, setContact] = useState(null);
    const [outcome, setOutcome] = useState('');
    const [onCall] = useState(on_call);
    const minutes = parseInt(moment().format('mm'))


    const [err, setErr] = useState(false);

    const [validationErr, setValidationErr] = useState(false);
    const [note, setNote] = useState('');

    const [success, setSuccess] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [state, setState] = useState({});

    const { start, givenName, familyName, companySize, company_name, email, phone } = state;

  
    
    const onSubmit = useCallback(async () => {
        if(!outcome || outcome === false) setValidationErr(true)

    
        // let live = getUrlParameter('live');
        // live = live === 'true' ? true : false 

        setDisabled(true)
        const data = await _nylas.finish({outcome, note, contact: contact._id})
        setDisabled(false)

        if(!data.success) return toggleAlertBS(true, 'Call could not be dispositioned, manual step move needed inside IntuiDesk')
        setNeedsOutcome(false)
        setSuccess(true)
       
    }, [outcome, note, contact, setNeedsOutcome])

    const fetchContact = useCallback(async (id) => {
        if(!id) return
        const c = await _contacts.findById(id)
        if(c.data) return setContact(c.data);
        return setErr(true);
    }, [])

    useEffect(() => {
        setNeedsOutcome(true)
        if(onCall.contact) fetchContact(onCall.contact)
    }, [onCall.contact, fetchContact, setNeedsOutcome])

    if(err) return (
        <Container className="pt-5 text-center">
            <p className=''><b className='text-underline'>No contact could be found for this call</b>. Manual action must be taken to create a case and move steps for {formatPhone(onCall.call_phone_number)}</p>
            <h4 className='font-weight-bold text-warning'><a href={keys.APP_URL} target="_blank" rel="noopener noreferrer"><i className="fas fa-link mr-2 " /> OPEN DASHBOARD</a></h4>
        </Container>
    )

    if(!contact) return <Circle />


    return (
       <Container className="pt-5">
        

            <div style={{maxWidth: 360, margin: 'auto', }} className="z-depth-5 border bg-info rounded pb-3">
                <div className="text-centr pl-3 pt-3 ml-1">
                    <img src={Logo} style={{width: 225}} alt="Biz Head Law" />
                </div>

                {success ? (
                    <div className="px-5 pt-3 mb-1">
                        <h2  className='text-white'>
                            <i className="fas fa-check text-success mr-2" /> Consultation Outcome Recorded, This Page Will Close After Your Call Ends.
                        </h2>
                    </div>
                ) : err ? (
                    <div className="px-5 pt-3 mb-1">
                        <h2 >
                            <i className="fas fa-exclamation-triangle text-primary mr-2" /> This page has expired.
                        </h2>
                    </div>
                ) : (
                    <>
                    <div>
                         <div className="px-5 pt-3 pb-4">
                            <h2 className="text-white display-4 mb-0">Live Consultation </h2>
                            

                            <p className="text-white mb-0">{moment.unix(now).format('dddd MMM Do, YYYY - h:mm A')}</p>

                            <p className='pt-4 text-muted'>Enter the outcome of the call with: </p>

                            <div className="rounded  border-left px-3 pb-3 pt-1 mb-4">
                                <p className="text-sm mb-0">
                                    <span className="d-block text-white font-weight-bold text-capitalize text-underline">{renderName(contact)}</span>
                                </p>
                                <p className="text-sm mb--2 text-muted mt-3 text-capitalize">{onCall.company_name}</p>
                                <p className="text-sm mb--2 text-muted">Size: {onCall.company_size}</p>
                                <p className="text-sm mb--2 text-muted">Email: {contact.email}</p>
                                <p className="text-sm mb--2 text-muted">Phone: {formatPhone(onCall.call_phone_number)}</p>

                                <a href={`${keys.APP_URL}/dashboard/contacts/view/${contact._id}`} target="_blank" rel="noopener noreferrer" className='btn btn-success mt-4'>Open Contact File <i className="fas fa-link ml-3" /></a>

                                {/* <p className="text-sm mb-0">{phone}</p> */}
                            </div>

                            

                            <FormGroup>
                                <label className="form-control-label">Consultation Outcome*</label>
                                <Input 
                                    onChange={(e) => setOutcome(e.target.value)}
                                    value={outcome}
                                    invalid={validationErr}
                                    type="select"
                                >
                                    <option value="false">select outcome</option>
                                  
                                    <optgroup label="Not Applicable">
                                        <option value="Did Not Answer">Did Not Answer</option>
                                    </optgroup>

                                    <optgroup label="Does Not Qualify">
                                        <option value="Showed - DNQ (not impacted)">Showed - DNQ (not impacted)</option>
                                        <option value="Showed - DNQ (only W2 is owner/family member)">Showed - DNQ (only W2 is owner/family member)</option>
                                        <option value="Showed - DNQ (not interested)">Showed - DNQ (not interested)</option>
                                        <option value="Showed - DNQ (already applied)">Showed - DNQ (already applied)</option>
                                    </optgroup>

                                    <optgroup label="Additional Action Needed">
                                        <option value="Showed - DNH - needs for information">Showed - DNH - needs for information</option>
                                        <option value="Showed - needs follow up call with senior lawyer">Showed - needs follow up call with senior lawyer</option>
                                        <option value="Showed - needs agreement 20%">Showed - needs agreement 20%</option>
                                        <option value="Showed - needs agreement 18%">Showed - needs agreement 18%</option>
                                        <option value="Showed - needs agreement 17.5%">Showed - needs agreement 17.5%</option>
                                        <option value="Showed - needs agreement 15%">Showed - needs agreement 15%</option>
                                    </optgroup>

                                    <optgroup label="No Action Needed">
                                        <option value="Just A Check In Call">Just A Check In Call</option>
                                    </optgroup>

                                </Input>
                                <FormFeedback>Please Enter The Outcome Of The Consultation.</FormFeedback>
                            </FormGroup>

                           

                            <FormGroup>
                                <label className="form-control-label">Note (Optional)</label>
                                <Input 
                                    type="textarea"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </FormGroup>

                           

                            {outcome && outcome !== false ? (
                                <p className="text-sm text-white">
                                    <small>
                                        Selected Outcome:{' '}
                                        <span className="d-block text-white">
                                            {outcome === 'Just A Check In Call' ? null :
                                                outcome === 'No Show - follow up needed' || outcome === 'No Show - cancelled - no follow up needed' ? (
                                                <i className="fas fa-times mr-2 text-danger " /> 
                                            ) : outcome === 'Showed - DNH - needs for information' || outcome.includes('Showed - needs agreement') || outcome === 'Showed - needs follow up call with senior lawyer' ? (
                                                <i className="fas fa-check mr-2 text-success " /> 
                                            ) : (
                                                <i className="fas fa-thumbs-down down mr-2 text-warning " /> 
                                            )}
                                            {' '}
                                            {outcome}
                                        </span>
                                    </small>
                                </p>
                            ) : null}

                        </div>


                        <div className='bg-dark'>
                            <div className="px-5 pt-4 mb-1 pb-3 border-top">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="customCheckRegister"
                                        type="checkbox"
                                    />
                                    <label className="custom-control-label" htmlFor="customCheckRegister">
                                        <span className="text-white" style={{position: 'relative', top: -2}}>
                                            <Badge style={{position: 'relative', top: -2}} color="success">NEW!</Badge> I was able to stay on with the contact to walk them through signing their AND I asked the person to sign the Client Services Agreement
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="px-5 pt-4 mb-1 pb-2 border-top">
                            <button disabled={disabled} onClick={onSubmit} className="btn btn-warning btn-block btn-lg mb-0">
                                <i className="fas fa-paper-plane mr-2 " /> Submit
                            </button>
                        </div>
                    </div>
                    </>
                )}

            </div>

       </Container>
    )
}


const mapStateToProps = state => {

    return {
        on_call: state.state.on_call,
        viewing_user: state.auth.viewing_user,
        browser_dialing_status    : state.system.browser_dialing_status,
    };
};

export default connect(mapStateToProps, '')(Book);
