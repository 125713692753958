
import CallCenterUsers from '../pages/call_center';

const routes = () => {

    return[

        /***********************
        Call Center
        ************************/
        {
            path: "/",
            component: CallCenterUsers,
        },
       

    ]

}

export default routes;