/*
Documentation

renders the bottom bar of the call center to have information about the contact

*/

import React, { Component } from 'react';
import { UncontrolledTooltip, Container } from 'reactstrap';
import { architeckCall } from 'database'
import { connect } from 'react-redux'
import TransferModal from '../TransferModal'
import * as callCenterActions from 'store/functions/call_center/call_center'
import keys from 'keys'
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

import KeypadOnCall from './KeypadOnCall'
import { Device } from 'twilio-client';
import A from 'components/markup/links/A';

import { setViewingUser } from 'store/functions/auth/auth';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';


class OnCallBanner extends Component {

    state = {
        showModal: false,
        putOnHold: false,
        putOnMute: false,
        showKeypad: false,
        disableHold: false,
        disableMute: false
    };

    toggleKeypad = () => this.setState({showKeypad: !this.state.showKeypad})

    toggleTransferModal = () => {

        // do nothing if not in the call
        if(this.props.viewing_user.call_conference) {
            this.setState({showModal: !this.state.showModal})
        }
    }

    toggleHold = async () => {

        const { disableHold, putOnHold} = this.state;
        const { viewing_user, call_status } = this.props;

        if(disableHold) return;

        // do nothing if not in the call
        if(viewing_user.call_conference && call_status.contact && call_status.contact._id) {

            this.setState({disableHold: true})

            const call = await architeckCall({
                shouldNotShowAndLogError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/calls/toggle_hold',
                data: {
                    ConferenceSid: viewing_user.call_conference,
                    contact_id: call_status.contact._id,
                }
            })

            // give slight delay to prevent double clicks
            // if successfully put on hold toggle the hold UI
            setTimeout(() => {
                this.setState({
                    disableHold: false,
                    putOnHold: call.data ? call.data.hold : putOnHold
                })
            }, 100)

        }

    }

    toggleMuted = async () => {

        const { viewing_user } = this.props;

        if(this.state.disableMute) return;

        // do nothing if not in the call
        if(viewing_user.call_conference) {

            this.setState({disableMute: true})

            const call = await architeckCall({
                shouldNotShowAndLogError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/calls/toggle_muted',
                data: {
                    ConferenceSid: viewing_user.call_conference,
                    phone: viewing_user.phone,
                    user_id: viewing_user._id
                }
            })

             // give slight delay to prevent double clicks
            // if successfully put on hold toggle the hold UI
            setTimeout(() => {
                this.setState({
                    disableMute: false,
                    putOnMute: call.data ? call.data.muted : this.state.putOnMute
                })
            }, 100)

        } else {

            const muted = Device.activeConnection().mediaStream.isMuted;
            Device.activeConnection().mute(!muted);
            this.setState({
                disableMute: false,
                putOnMute: !muted
            })

        }

    }

    endCall = async () => {

        const { call_conference } = this.props.viewing_user
        const { finishCall } = this.props.call_status

        if(finishCall) this.props.call_status.finishCall(this.props.viewing_user.call_conference)
        callCenterActions.setOffCall()
        callCenterActions.toggleEndCallModal(true)

        // try to end a phone call
        // if(call_conference) {

            const isBrowser = this.props.viewing_user.call_phone_default === 'browser'

            await architeckCall({
                shouldNotShowAndLogError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/calls/end_call',
                data: {
                    ConferenceSid: call_conference,
                    endForPhone: isBrowser ? undefined : this.props.viewing_user.phone,
                    userID: this.props.viewing_user._id
                }
            })

            // try to end browser call, if failed it means its not a browser call
            try { Device.disconnectAll() } catch(e) {}

        // }

    }

    // when the end button is pressed the server should fire of this event
    // here will kill the on call banner on every screen so show they are
    // of call no matter how many tabs they have open.
    onUserEndedCall = (data) => {

        if(data.data === this.props.viewing_user._id) {

            const { finishCall } = this.props.call_status
            callCenterActions.setOffCall()

            if(finishCall) finishCall(this.props.viewing_user.call_conference)
        }

    }

    onCallStarted = async (data) => {

        if(data.data._id === this.props.viewing_user._id) {

            const contact = data.data.call_contact;
            if(!contact) return;

            callCenterActions.setOnCall({
                ...this.props.call_status,
                contact: contact,
                contact_name: renderName(contact),
                contact_phone: contact.phone,
                contact_email: contact.email,
            })

            this.setState({
                onCall: true,
                appointment: data.data.call_appointment ? data.data.call_appointment : null,
                contact: contact._id,
                contact_name: renderName(contact),
                contact_email: contact.email,
                contact_phone: contact.phone,
            })

        }

    }

    getEmail = (email) => {
        if(!email) return '';
        if(email.length > 20) {
            const first = email.slice(0,7);
            const last = email.slice(email.length - 13, email.length)
            return first + '.....' + last
        }
        return email;
    }

    onHoldUpdated = (data) => {
        if(data.data && data.data.user_id === this.props.viewing_user._id) {
            this.setState({
                disableHold: false,
                putOnHold: data.data.hold
            })
        }
    }
    
    onMutedUpdated = (data) => {
        if(data.data && data.data.user_id === this.props.viewing_user._id) {
            this.setState({
                disableMute: false,
                putOnMute: data.data.muted
            })
        }
    }

    componentWillReceiveProps = async (nextProps) => {

        // when ending call take of mute and hold
        if(this.props.call_status.onCall && !nextProps.call_status.onCall) {
            this.setState({putOnMute: false, putOnHold: false, disableHold: false, disableMute: false})
        // when starting call also take of mute and hold
        } else if(!this.props.call_status.onCall && nextProps.call_status.onCall) {
            // wait 5 seconds and then refresh the user, this helps if a socket mis fired and the users
            // conference sid does not update

            setTimeout(() => {setViewingUser()}, 5000)
            this.setState({putOnMute: false, putOnHold: false, disableHold: false, disableMute: false})

        }
 
    }

    componentWillUnmount = () => {
        io.off(socketEvents.user_ended_call, this.onUserEndedCall)
        io.off(socketEvents.user_entered_call, this.onCallStarted)
    }

    componentDidMount = async (nextProps) => {

        io.on(socketEvents.user_ended_call, this.onUserEndedCall)
        io.on(socketEvents.user_entered_call, this.onCallStarted)
        io.on(socketEvents.hold_updated, this.onHoldUpdated)
        io.on(socketEvents.muted_updated, this.onMutedUpdated)

        // const user = this.props.viewing_user;

        // if(user && user.call_conference) {

        //     // if user.call_conference or user.call_appointment_in_person is set it means the user should be on a call / in person meeting
        //     // that means we should show the call screen. call_contact is just a check to make sure we have the data we need
        //     // in theory it should always be set if condition 1 is true
        //     if((user.call_conference || user.call_appointment_in_person) && user.call_contact && user.call_contact._id) {

        //         const values = await Promise.all([
        //             _contacts.findById(user.call_contact._id),
        //             _appointments.findById(user.call_conference ?  user.call_appointment_to_accept : user.call_appointment_in_person, true),
        //         ])

        //         const contact = values[0].data;
        //         const appointment = values[1];

        //         if(contact) {

        //             callCenterActions.setOnCall({
        //                 contact: contact,
        //                 contact_name: renderName(contact),
        //                 contact_phone: contact.phone,
        //                 contact_email: contact.email,
        //             })


        //             this.setState({
        //                 onCall: true,
        //                 appointment: appointment.data ? appointment.data : null,
        //                 contact: user.call_contact._id,
        //                 contact_name: renderName(contact),
        //                 contact_email: contact.email,
        //                 contact_phone: contact.phone,
        //             })

        //         }

        //     }

        // }
    }

    render() {

        const { showModal, putOnMute, putOnHold, showKeypad } = this.state;
        const { browser_dialing_status, device, on_call } = this.props
        const { contact_name, contact_phone, contact,  onCall, isIncoming, contact_email } = this.props.call_status
        const ConferenceSid = this.props.viewing_user ? this.props.viewing_user.call_conference : null

        if(!Object.keys(on_call).length) return <div></div>

        return (

            <div className="on-call-banner">
                <Container fluid>
                    <ul >

                        {browser_dialing_status === 'ready' ? (
                            <li>
                                <i className="fas fa-keyboard mr-0 text-warning" onClick={this.toggleKeypad} />
                            </li>
                        ) : null}

                        <li>
                            <i className="fas fa-phone mr-3 pulse" />
                            <span className="text-muted">
                                {contact_phone ? formatPhone(contact_phone) : null}
                            </span>
                        </li>

                        <li className="text-muted text-capitalize">{on_call.identifier ? on_call.identifier : 'No Name Found'}</li>

                        {/* {isIncoming ? null : (
                            <li onClick={() => this.toggleHold()} id="tooltip-hold" className={putOnHold ? 'active action-icon first' : 'action-icon first'}>
                                <i className="fas fa-pause" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltip-hold">Hold</UncontrolledTooltip>
                            </li>
                        )} */}
                        {/* {isIncoming ? null : (
                            <li onClick={() => this.toggleTransferModal()} id="tooltip-random" className="action-icon">
                                <i className="fas fa-random" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltip-random"  >Transfer</UncontrolledTooltip>
                            </li>
                        )} */}

                       
                        <li onClick={() => this.toggleMuted()} id="tooltip-mute" className={putOnMute ? 'active action-icon' : 'action-icon'}>
                            <i className="fas fa-volume-mute" />
                            <UncontrolledTooltip delay={0} placement="top" target="tooltip-mute">Mute</UncontrolledTooltip>
                        </li>

                        {on_call.contact ? (
                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${on_call.contact}?nav=5`}>
                                <li id="tooltip-edit" className="action-icon">
                                    <i className="fas fa-edit text-success" />
                                    <UncontrolledTooltip delay={0} placement="top" target="tooltip-edit">Edit Contact</UncontrolledTooltip>
                                </li>
                            </A>
                        ) : ''}
                      

                        <li onClick={() => this.endCall()} id="tooltip-end-call" className="action-icon">
                            <i className="fas fa-times text-danger" />
                            <UncontrolledTooltip delay={0} placement="top" target="tooltip-end-call">End Call</UncontrolledTooltip>
                        </li>

                    </ul>
                </Container>

                <TransferModal
                    contact_phone={contact_phone}
                    ConferenceSid={ConferenceSid}
                    contact={contact}
                    showModal={showModal}
                    toggleTransferModal={this.toggleTransferModal}
                />

                {browser_dialing_status === 'ready' ? (
                    <KeypadOnCall
                        toggleKeypad={this.toggleKeypad}
                        showKeypad={showKeypad}
                    />
                ) : null }

            </div>

        )

    }

}

const mapStateToProps = state => {
    return {
        on_call: state.state.on_call,
        device: state.device,
        call_status: state.call_center.call_status,
        viewing_user: state.auth.viewing_user,
        browser_dialing_status: state.system.browser_dialing_status,
    };
};

export default connect(mapStateToProps, '')(OnCallBanner);
