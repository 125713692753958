import { useState } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { setBrowserDialing } from 'store/functions/system/system';

import OffCall from './OffCall';
import OnCall from './OnCall';

const CallCenter = ({viewing_user, browser_dialing_status, on_call}) => {

    const [needsOutcome, setNeedsOutcome] = useState(false)

    if(browser_dialing_status !== 'ready') return (
        <div className='text-center py-3'>
            <Container fluid>
                <button onClick={() => setBrowserDialing(viewing_user._id)} className='btn btn-success btn-lg'>Take Live Calls</button>
            </Container>
        </div>
    )

    if(Object.keys(on_call).length || needsOutcome) return <OnCall setNeedsOutcome={setNeedsOutcome} />
    return <OffCall />

}


const mapStateToProps = state => {
    return {
        on_call: state.state.on_call,
        viewing_user: state.auth.viewing_user,
        browser_dialing_status    : state.system.browser_dialing_status,
    };
};

export default connect(mapStateToProps, '')(CallCenter);