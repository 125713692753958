import keys from 'keys';
import Axios from 'axios';

import fileDownloader from 'js-file-download';

import { toggleAlertBS } from 'store/functions/system/system';


const onDownloadDocument = async (doc, cb, hideErr) => {

    const _id = doc && doc._id ? doc._id : doc

    const result = await Axios({
        method: 'get',
        url: keys.API_URL + `/api/v2/core/documents/${_id}/download`,
        responseType:'arraybuffer',
        headers: {
            authorization: `Bearer ${keys.SYSTEM_API_KEY}`
        },
        withCredentials: true,
    })

    if(result.headers && result.headers['content-type'].includes('application/json')) {
        try {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
            var body = JSON.parse(decodedString);

            if(body.message && body.message.length) {
                console.log(body)
                const messageString = Array.isArray(body.message) ? body.message[0] : body.message
                if(cb) cb(messageString)
                if(hideErr) return
                return toggleAlertBS('info', messageString ? messageString : `Something's Not Right, Please Try Again Later`)
            }
        } catch(e) {
            console.log(e)
        }
        if(cb) cb('document error')
        if(hideErr) return;
        return toggleAlertBS('info', `Something's Not Right, Please Try Again Later`)
    }

    if(cb) return cb(null, result.data)
    fileDownloader(result.data, result.headers.filename) 

}

export const getImageAsBase64 = (data) => {
    function encode (input) {
        var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
    
        while (i < input.length) {
            chr1 = input[i++];
            chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index 
            chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here
    
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
    
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
                      keyStr.charAt(enc3) + keyStr.charAt(enc4);
        }
        return output;
    }
    var bytes = new Uint8Array(data);
    const converted = 'data:image/png;base64,'+encode(bytes)
    return converted;
}

export default onDownloadDocument;