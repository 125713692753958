import { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardTitle } from 'reactstrap'

import _live_queues from '_functions/live_queue'

import { Device } from 'twilio-client'

import moment from 'moment'

import Circle from 'components/markup/loading/Circle'
import { formatPhone } from 'utils/text';
import renderName from 'utils/renderName';

const CallCenter = ({viewing_user, browser_dialing_status}) => {


    const [hold, setHold] = useState(null)
    const [callback, setCallback] = useState(null)

    const fetchCallbacks = useCallback(async () => {
        const values = await Promise.all([
            _live_queues.callback(),
        ])

        if(values[0].data) setCallback(values[0].data)
        // console.log(data)
    }, [])
    const fetchHold = useCallback(async () => {
        const values = await Promise.all([
            _live_queues.hold(),
        ])

        if(values[0].data) setHold(values[0].data)
        // console.log(data)
    }, [])

    const onCallOut = useCallback((callback, conference) => {
        Device.connect({
            id: callback._id,
            _id: callback.contact ? callback.contact._id: 'null',
            user: viewing_user._id,
            contact: callback.contact._id,
            identifier: renderName(callback.contact),
            isTransfer: false,
            isMuted: false,
            directDial: true,
            call_phone_number: callback.contact ? callback.contact.phone : '',
            phone: callback.phone,
            case: callback.case ? callback.case._id : '',
            company_name: callback.case ? callback.case.name : '',
            company_size: callback.company_size,
            conference: conference ? callback.conference_sid : '',
        });
    }, [viewing_user._id])

    useEffect(() => {
        if(browser_dialing_status === 'ready') {
            fetchCallbacks()
            fetchHold();

            let interval1 = setInterval(() => [
                fetchCallbacks()
            ], 2000)

            let interval2

            setTimeout(() => {
                interval2 = setInterval(() => [
                    fetchHold()
                ], 2000)
            }, 1000)

            return () => {
                clearInterval(interval1)
                if(interval2) clearInterval(interval2)
            }
        }
        
    }, [fetchCallbacks, fetchHold, browser_dialing_status])


    return (
        <div>
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">On Hold</CardTitle>
                            </CardHeader>

                            {!hold ? <Circle /> : (
                                <div className='table-responsive'>
                                    <table className='table'>

                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Size</th>
                                            <th>Phone</th>
                                            <th>Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {hold.map(h => (
                                            <tr>
                                                <td>{moment.unix(h.time_requested).fromNow()}</td>
                                                <td>{h.company_size}</td>
                                                <td>{formatPhone(h.phone)}</td>
                                                <td>{h.contact ? renderName(h.contact) : '-'}</td>
                                                <td className='text-right'><button onClick={() => onCallOut(h, true)} className='btn btn-success'>Call</button></td>
                                            </tr>
                                        ))}
    
                                    </tbody>
                                    </table>
                                </div>
                            )}

                           
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Needs A Call</CardTitle>
                            </CardHeader>

                            {!callback ? <Circle /> : (
                                <div className='table-responsive'>
                                    <table className='table'>

                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Size</th>
                                            <th>Phone</th>
                                            <th>Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {callback.map(h => (
                                            <tr>
                                                <td>{moment.unix(h.time_requested).fromNow()}</td>
                                                <td>{h.company_size}</td>
                                                <td>{formatPhone(h.phone)}</td>
                                                <td>{h.contact ? renderName(h.contact) : '-'}</td>
                                                <td className='text-right'><button onClick={() => onCallOut(h)} className='btn btn-success'>Call</button></td>
                                            </tr>
                                        ))}
    
                                    </tbody>
                                    </table>
                                </div>
                            )}

                           
                        </Card>
                    </Col>
                    <Col lg={6}>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        browser_dialing_status    : state.system.browser_dialing_status,
    };
};

export default connect(mapStateToProps, '')(CallCenter);